import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  NavigationV2,
  PopupAccordionMenuLink,
  HookComponentProvider,
  Phone,
  LocationsContext,
  OutboundLink,
  FishermanIcon,
  RecaptchaProvider,
  EmailCapture,
  Location,
  Email,
  SiteMap,
  CopyrightLabel,
  SocialMediaV2,
  MultipleComponentIterator,
  FishermanBanner,
  PageTransition,
} from "@bluefin/components";
import { Header, Button, Grid } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  joinParentWithChildPages,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "../semantic/semantic.less";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            url
            pageType
            title
            navigationPriority
            groupName
            props
            _id
          }
        }
        fishermanBusiness {
          name
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 80)
            }
          }
          _id
          type
          brandIdentity
          socialMedia {
            _id
            link
            type
          }
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
        allSitePage {
          nodes {
            path
            context {
              title
              fishermanParentPageId
              tagName
              fishermanParentPageType
              navigationOrder
              navigationGroup
              navigationGroupOrder
            }
          }
        }
        bookingUrl: fishermanBusinessWebsitePage(
          title: { regex: "/Book Now|Make An Appointment/" }
          pageType: { eq: "External" }
        ) {
          url
        }
        footerRegularPages: allFishermanBusinessWebsitePage(
          filter: { pageType: { ne: "Services" } }
        ) {
          nodes {
            _id
            title
            slug
            pageType
            url
            navigationPriority
            props
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        allSitePage,
        bookingUrl,
        footerRegularPages,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.beautifulinsideandoutspa.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://www.beautifulinsideandoutspa.com"}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <NavigationV2
                fullScreen={true}
                desktop={
                  <React.Fragment>
                    <NavigationV2.Container
                      containerId={"desktop-navigation-bar"}
                    >
                      <NavigationV2.Menu>
                        <NavigationV2.Logo
                          title={<Header>{fishermanBusiness.name}</Header>}
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                      </NavigationV2.Menu>
                      <NavigationV2.Menu>
                        <NavigationV2.Links
                          links={joinParentWithChildPages({
                            pages: filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            }),
                            generatedPages: allSitePage.nodes,
                          })}
                          renderChildPages={
                            <PopupAccordionMenuLink
                              targetPositionId={"desktop-navigation-bar"}
                            />
                          }
                        />
                        <NavigationV2.Links
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
                mobile={
                  <React.Fragment>
                    <NavigationV2.Container className={"menu-header"}>
                      <NavigationV2.Logo
                        title={<Header>{fishermanBusiness.name}</Header>}
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                      <div className={"actions-container"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <Phone
                              iconName={"phone"}
                              event={{
                                category: "Contact",
                                action: "Call",
                                label: "Navigation",
                              }}
                            />
                          }
                          hookToPropsMap={{
                            phone: "activeLocation.phoneNumber",
                          }}
                          hookParams={[LocationsContext]}
                          requiredProps={["phone"]}
                          onlyRenderOnClientSide={true}
                        />
                        <Button
                          as={OutboundLink}
                          to={bookingUrl.url}
                          event={{
                            category: "Booking",
                            action: "Schedule Appointment Intent",
                          }}
                          content={
                            <FishermanIcon iconName={"calendar-plus-regular"} />
                          }
                          aria-label={"Book an Appointment"}
                        />
                      </div>
                      <NavigationV2.Toggle />
                    </NavigationV2.Container>
                    <NavigationV2.Container
                      tray={true}
                      vertical={true}
                      className={"menu-content"}
                    >
                      <div className={"menu-content-header"}>
                        <NavigationV2.Logo
                          title={<Header>{fishermanBusiness.name}</Header>}
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                        <NavigationV2.Toggle />
                      </div>
                      <NavigationV2.Menu>
                        <NavigationV2.Links
                          links={joinParentWithChildPages({
                            pages: filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            }),
                            generatedPages: allSitePage.nodes,
                          })}
                        />
                        <NavigationV2.Links
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
              />
            </React.Fragment>
            <RecaptchaProvider>
              <main className={"page-container"}>{children}</main>
              <footer>
                <Grid stackable={true} doubling={true}>
                  <Grid.Row className={"subscribe-row"}>
                    <Grid.Column
                      className={"content-column"}
                      width={8}
                      textAlign={"left"}
                    >
                      <NavigationV2.Logo
                        title={<Header>{fishermanBusiness.name}</Header>}
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                      <p className={"subscribe-text-primary"}>
                        Subscribe To Our Newsletter
                      </p>
                      <p className={"subscribe-text-secondary"}>Stay updated</p>
                    </Grid.Column>
                    <Grid.Column
                      className={"email-capture-column"}
                      width={8}
                      textAlign={"right"}
                    >
                      <HookComponentProvider
                        hook={useContext}
                        component={
                          <EmailCapture
                            businessId={fishermanBusiness._id}
                            businessType={fishermanBusiness.type}
                            type={"footer"}
                          />
                        }
                        hookToPropsMap={{ locationId: "activeLocation.id" }}
                        hookParams={[LocationsContext]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={"location-select-row"} />
                  <Grid.Row className={"contact-info-and-links-row"}>
                    <Grid.Column className={"contact-info-column"} width={3}>
                      <strong>Contact</strong>
                      <HookComponentProvider
                        hook={useContext}
                        component={
                          <Location
                            inline={true}
                            businessName={fishermanBusiness.name}
                            showBusinessName={false}
                          />
                        }
                        hookToPropsMap={{
                          "address.street": "activeLocation.street",
                          "address.city": "activeLocation.city",
                          "address.state": "activeLocation.state",
                          "address.zip": "activeLocation.zip",
                        }}
                        requiredProps={[["address"]]}
                        hookParams={[LocationsContext]}
                      />
                      <HookComponentProvider
                        hook={useContext}
                        component={Phone}
                        hookToPropsMap={{ phone: "activeLocation.phoneNumber" }}
                        hookParams={[LocationsContext]}
                        onlyRenderOnClientSide={true}
                      />
                      <HookComponentProvider
                        hook={useContext}
                        component={Email}
                        hookToPropsMap={{ email: "activeLocation.email" }}
                        hookParams={[LocationsContext]}
                        onlyRenderOnClientSide={true}
                      />
                    </Grid.Column>
                    <Grid.Column className={"sitemap-column"} width={3}>
                      <strong>Menu</strong>
                      <SiteMap
                        links={footerRegularPages.nodes}
                        horizontal={false}
                        bulleted={false}
                      />
                    </Grid.Column>
                    <Grid.Column className={"copyright-column"} width={5}>
                      <strong>Info</strong>
                      <CopyrightLabel
                        company={fishermanBusiness.name}
                        phrase={"All Rights Reserved"}
                        privacyPolicyLink={
                          <OutboundLink
                            to={
                              "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                            }
                            label={"Privacy Policy"}
                            eventLabel={"copyright-privacy-policy"}
                          />
                        }
                        brand={fishermanBusiness.brandIdentity}
                      />
                    </Grid.Column>
                    <Grid.Column className={"social-media-column"} width={5}>
                      <SocialMediaV2>
                        <MultipleComponentIterator
                          iterator={fishermanBusiness.socialMedia}
                          components={[
                            {
                              component: <SocialMediaV2.Platform />,
                              propMap: {
                                key: "_id",
                                url: "link",
                                platform: "type",
                              },
                              children: [
                                {
                                  component: <SocialMediaV2.Icon />,
                                  propMap: { icon: "type" },
                                },
                              ],
                            },
                          ]}
                        />
                      </SocialMediaV2>
                      <NavigationV2.Logo
                        title={<Header>{fishermanBusiness.name}</Header>}
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <FishermanBanner brand={fishermanBusiness.brandIdentity} />
              </footer>
            </RecaptchaProvider>
            <PageTransition />
          </div>
        </React.Fragment>
      );
    }}
  />
);
